import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'value',
    'percentage',
    'count'
  ]

  static values = {
    interval: {
      type: Number,
      default: 75,
    },
    threshold: {
      type: Number,
      default: 0.9
    },
    value: {
      type: Number,
      default: 2.4
    },
    count: {
      type: Number,
      default: 21
    }
  }

  initialize() {
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this)
    this.increment = this.increment.bind(this)
  }

  connect() {
    const options = {
      rootMargin: '0px',
      threshold: this.thresholdValue
    }
    this.observer = new IntersectionObserver(this.intersectionObserverCallback, options)
    this.observer.observe(this.element)
    this.render()

  }

  disconnect() {
    this.observer.unobserve(this.element)
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  intersectionObserverCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > this.thresholdValue) {
        const target = entry.target
        observer.unobserve(target)
        this.start()
      }
    })
  }

  start() {
    this.interval = setInterval(this.increment, this.intervalValue)
  }

  increment() {
    this.valueValue = this.valueValue + 0.02
    this.countValue = this.countValue + 1
    this.render()
    if (this.valueValue > 5) {
      clearInterval(this.interval)
    }
  }

  render() {
    this.valueTarget.textContent = this.valueValue.toFixed(1)
    this.countTarget.textContent = this.countValue
    const percentage = this.valueValue / 5.0 * 100
    this.percentageTarget.style.width = `${percentage}%`
  }

}


