import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'input',
    'form'
  ]

  connect() {
    this.update(this.inputTarget.checked)
  }

  change(event) {
    const billingSame = event.target.checked
    this.update(billingSame)
  }

  update(checked) {
    if (checked) {
      this.formTarget.classList.add('hidden')
    } else {
      this.formTarget.classList.remove('hidden')
    }
  }

}