import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'code',
    'name',
    'amount'
  ]

  change(event) {
    const { target: select } = event
    const option = select.options[select.selectedIndex]
    const { code, name, amount } = option.dataset
    if (this.hasCodeTarget) this.codeTarget.value = code || ''
    if (this.hasNameTarget) this.nameTarget.value = name || ''
    if (this.hasAmountTarget) this.amountTarget.value = amount || '0.00'
  }
}