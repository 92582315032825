import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'givenName',
    'familyName',
    'phone'
  ]

  connect() {
    this.change = this.change.bind(this)
    document.addEventListener('autocomplete.change', this.change)
  }

  disconnect() {
    document.removeEventListener('autocomplete.change', this.change)
  }

  change(event) {
    const { detail } = event
    const user = JSON.parse(detail.value)
    if (this.hasGivenNameTarget) {
      this.givenNameTarget.value = user.given_name
    }
    if (this.hasFamilyNameTarget) {
      this.familyNameTarget.value = user.family_name
    }
    if (this.hasPhoneTarget) {
      this.phoneTarget.value = user.phone
    }
  }

}