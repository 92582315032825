import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'value'
  ]

  static values = {
    interval: {
      type: Number,
      default: 75,
    },
    threshold: {
      type: Number,
      default: 0.9
    },
    value: {
      type: Number,
      default: Math.floor(Math.random() * 500) + 200
    }
  }

  initialize() {
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this)
    this.increment = this.increment.bind(this)
  }

  connect() {
    const options = {
      rootMargin: '0px',
      threshold: this.thresholdValue
    }
    this.observer = new IntersectionObserver(this.intersectionObserverCallback, options)
    this.observer.observe(this.element)
    this.render()
  }

  disconnect() {
    this.observer.unobserve(this.element)
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  intersectionObserverCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.start()
      } else {
        this.stop()
      }
      // if (entry.intersectionRatio > this.thresholdValue) {
      //   const target = entry.target
      //   observer.unobserve(target)
      //   this.start()
      // }
    })
  }

  start() {
    this.interval = setInterval(this.increment, this.intervalValue)
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  increment() {
    this.valueValue = this.valueValue + 3
    this.render()
  }

  render() {
    this.valueTarget.textContent = this.valueValue.toLocaleString()
  }

}


