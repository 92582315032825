import { Controller } from '@hotwired/stimulus'
import WOW from 'wowjs'

export default class extends Controller {

  connect() {
    this.cache = this.cache.bind(this)
    this.addListeners()
    const wow = new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 100,
      live: false
    })
    wow.init()
  }

  disconnect() {
    this.removeListeners()
  }

  addListeners() {
    document.addEventListener('turbo:before-cache', this.cache)
  }

  removeListeners() {
    document.removeEventListener('turbo:before-cache', this.cache)
  }

  async cache() {
    /*
    const wows = [].slice.call(document.querySelectorAll('.wow'))
    wows.map(element => { 
      element.classList.remove('wow')
      element.style = 'visibility: hidden'
    })
    */
  }

}