import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  
  static targets = [
    'trigger',
    'card'
  ]
  static values = { 
    url: String,
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      type: String,
      default: 'top'
    }
  }

  click(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  async show(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.popover) {
      this.popover.show()
      return
    }
    if (this.hasCardTarget) {
      this.initializePopover()
    } else {
      const response = await get(this.urlValue, {
        contentType: 'text/html'
      })
      if (response.ok) {
        const html = await response.html
        const fragment = document.createRange().createContextualFragment(html)
        this.element.appendChild(fragment)
        this.initializePopover()
      }
    }
  }

  close() {
    this.popover.hide()
  }
 
  initializePopover() {
    const options = {
      triggerType: this.triggerValue,
      placement: this.placementValue
    }
    this.popover = new Popover(
      this.cardTarget,
      this.triggerTarget, 
      options
    )
    this.popover.show()
  }

  disconnect() {
    this.popover = null
    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }
  }

}