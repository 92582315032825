import { Controller } from '@hotwired/stimulus'
import { Accordion } from 'flowbite'

export default class extends Controller {

  connect() {
    const items = []
    this.element.querySelectorAll('[data-accordion-target]').forEach(el => {
      const item = {
        id: el.getAttribute('data-accordion-target'),
        triggerEl: el,
        targetEl: document.querySelector(el.getAttribute('data-accordion-target')),
        icon: el.querySelector('[data-accordion-icon]'),
        active: el.getAttribute('aria-expanded') === 'true' ? true : false
      }
      items.push(item)
    }) 
    const options = {
      alwaysOpen: false,
      activeClasses: 'bg-slate-100 dark:bg-slate-800 text-slate-900 dark:text-white',
      inactiveClasses: 'text-slate-500 dark:text-slate-400'
    }
    this.accordion = new Accordion(
      this.element, 
      items,
      options
    )
  }

  disconnect() {
    this.accordion = null
  }

}