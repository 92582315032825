import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {

  static values = {
    url: String
  }

  async change(event) {
    const code = event.target.value
    const response = await post(this.urlValue, {
      body: { code: code },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      const text = await response.text
      Turbo.renderStreamMessage(text)
    } else {
      throw new Error(`Server responded with status ${response.status}`)
    }
  }

}