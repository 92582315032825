document.addEventListener("turbo:load", async (_event) => {
  const videos = document.querySelectorAll('video')
  videos.forEach(video => {
    video.load()
  })
  /*
  videos.forEach(video => {
    if (video.poster) {
      video.load()
    } else {
      video.load()
    }
  })
  */
})

document.addEventListener('turbo:before-cache', async (_event) => {
  const videos = [].slice.call(document.querySelectorAll('video'))
  videos.map(element => element.remove())
})