import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    kind: String
  }

  static targets = [
    'social',
    'google',
    'custom',
    'vcard'
  ]

  connect() {
    this.render(this.kindValue)
  }

  change(event) {
    const { target: select } = event
    const option = select.options[select.selectedIndex]
    this.render(option.value)
  }

  render(value) {
    console.log(value)
    switch(value) {
      case 'instagram': case 'facebook': case 'tiktok':
        this.renderSocial()
        break
      case 'google':
        this.renderGoogle()
        break
      case 'custom':
        this.renderCustom()
        break
      case 'vcard':
        this.renderVcard()
        break
    }

  }

  renderSocial() {
    this.socialTarget.removeAttribute('hidden')
    this.googleTarget.setAttribute('hidden', true)
    this.customTarget.setAttribute('hidden', true)
    this.vcardTarget.setAttribute('hidden', true)
  }

  renderGoogle() {
    this.socialTarget.setAttribute('hidden', true)
    this.googleTarget.removeAttribute('hidden')
    this.customTarget.setAttribute('hidden', true)
    this.vcardTarget.setAttribute('hidden', true)
  }

  renderCustom() {
    this.socialTarget.setAttribute('hidden', true)
    this.googleTarget.setAttribute('hidden', true)
    this.customTarget.removeAttribute('hidden')
    this.vcardTarget.setAttribute('hidden', true)
  }

  renderVcard() {
    this.socialTarget.setAttribute('hidden', true)
    this.googleTarget.setAttribute('hidden', true)
    this.customTarget.setAttribute('hidden', true)
    this.vcardTarget.removeAttribute('hidden')
  }
}