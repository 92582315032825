import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  connect() {
    this.element.dataset['action'] = 'submit->disable-button#disableForm'
  }

  disableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = true
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("button[type='submit']")
  }

}