import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    'menu',
    'content',
    'template'
  ]

  static values = {
    url: String
  }

  connect() {
    const placement = this.menuTarget.dataset.placement || 'right'
    this.onShow = this.onShow.bind(this)
    this.onHide = this.onHide.bind(this)
    const options = {
      placement: placement,
      backdropClasses: 'bg-slate-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      bodyScrolling: false,
      onHide: () => this.onHide(),
      onShow: () => this.onShow()
    }
    this.drawer = new Drawer(this.menuTarget, options)
    this.menuTarget.classList.remove('hidden')
    this.uncacheDrawer = this.uncacheDrawer.bind(this)
    document.addEventListener('turbo:before-cache', this.uncacheDrawer)
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.uncacheDrawer)
  }

  async show(event) {
    if (event.params.id) {
      const { currentTarget: button } = event
      button.disabled = true
      const response = await post(this.urlValue, {
        body: JSON.stringify(event.params),
        responseKind: 'turbo-stream'
      })
      if (response.ok) {
        const text = await response.text
        Turbo.renderStreamMessage(text)
        this.drawer.show()
      }
      button.disabled = false
    } else {
      this.drawer.show()
    }

  }

  hide() {
    this.drawer.hide()
  }

  onShow() {
    if (!this.hasContentTarget) return
    const content = this.templateTarget.innerHTML
    this.contentTarget.innerHTML = content
    this.contentTarget.src = this.urlValue
  }

  onHide() {
    if (!this.hasContentTarget) return
    this.contentTarget.removeAttribute('src')
    this.contentTarget.replaceChildren()
  }

  async uncacheDrawer(_event) {
    this.hide()
  }

}